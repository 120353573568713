<template>
  <div class="page-content" v-if="sequence">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Level Mission"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Level Mission</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group label="LEVEL" label-for="h-sequence_mark" label-cols-md="3">
              <b-input v-model="sequence.sequence_mark" type="number" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Mission Name"
              label-for="h-mission-name"
              label-cols-md="3"
            >
              <ui-component :component-data="sequence.name" class="border" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Mission Description"
              label-for="h-mission-description"
              label-cols-md="3"
            >
              <ui-component
                :component-data="sequence.description"
                class="border"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="NUMBER OF COMPLETED LESSONS"
              label-for="h-required_progress_mark"
              label-cols-md="3"
            >
              <b-input v-model="sequence.required_progress_mark" type="number" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteSequence"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
export default {
  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      sequence: null,
      select_rewards: [
        {
          text: "Box",
          value: "BOX",
        },
        {
          text: "Token",
          value: "TOKEN",
        },
      ],
    };
  },
  computed: {
    sequenceId() {
      return this.$route.params.sequence_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Level Mission List",
          to: { name: "quest-sequence-list" },
        },
      ];
      items.push({ text: "Level Mission Detail", active: true });
      return items;
    },
  },
  async created() {
    this.getSequence();
  },
  methods: {
    async getSequence() {
      this.sequence = await service.get({ id: this.sequenceId });
    },
    async update() {
      this.isUpdating = true;
      if (this.sequence.required_progress_mark && this.sequence.sequence_mark) {
        await service.update({
          data: JSON.stringify(this.sequence),
        });
      } else {
        this.$store.dispatch("pushErrorNotify", {
          text: "Progress mark and Sequense mark field required",
        });
      }

      this.isUpdating = false;
    },
    async deleteSequence() {
      await service.delete({ id: this.sequenceId });
      this.$router.push({ name: "quest-sequence-list" });
    },
  },
};
</script>
